import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';

import defaultRecommendedImg from '../../../assets/developImg/defaultRecommendedImg.png';
import categoryHalal from '../../../assets/developImg/category_halal.webp';
import categoryVegetarian from '../../../assets/developImg/category_vegetarian.png';
import categoryItalian from '../../../assets/developImg/category_italian.png';
import categoryNyonya from '../../../assets/developImg/category_nyonya.png';
import categoryJapanese from '../../../assets/developImg/category_japanese.png';
// import categoryKorean from '../../../assets/developImg/category_korean.png';
import categoryThai from '../../../assets/developImg/category_thai.png';
import categoryWestern from '../../../assets/developImg/category_western.png';


const RecommendedWithCategoriesRestaurantCards = ({ restaurantsInfo, clientInfo }) => {
  const history = useHistory();
  const [recommendedCategories, setRecommendedCategories] = useState([]);
  const categories = [
    "Halal",
    "Vegetarian",
    "Italian",
    "Nyonya",
    "Japanese",
    // "Korean",
    "Thai",
    "Western",
  ]

  useEffect(() => {
    // Map categories to their respective images
    const categoryImages = {
      "Halal": categoryHalal,
      "Vegetarian": categoryVegetarian,
      "Italian": categoryItalian,
      "Nyonya": categoryNyonya,
      "Japanese": categoryJapanese,
      // "Korean": categoryKorean,
      "Thai": categoryThai,
      "Western": categoryWestern,
    }

    const recommendedCategories = categories.map(category => ({
      name: category,
      image: categoryImages[category] || null,
    }));

    setRecommendedCategories(recommendedCategories);
  }, []);

  const handleCategoryClick = async (categoryName) => {
    history.push(`/restaurantsCategory/${categoryName.toLowerCase()}`);
  };

  return (
    <>
      <div className="mb-5">
        {Array.isArray(recommendedCategories) && recommendedCategories.length > 0 ? (
          <>
            <h6 className="mt-5 fw-bold">Foodie Finds: What's Your Flavor? </h6>
            <div style={{ width: '100%', height: '100px', overflow: 'auto', marginTop: '10px' }}>
              <div className="photo-grid" style={{ display: 'flex', overflowX: 'auto', gap: '10px' }}>
                {recommendedCategories.map((category, index) => (
                  <div 
                    key={index} 
                    style={{ flexShrink: '0', position: 'relative', width: '100px', height: '100px', borderRadius: '10px', overflow: 'hidden' }}
                    onClick={() => handleCategoryClick(category.name)}
                  >
                    <img
                      className="img-fluid rounded-5"
                      src={category.image || defaultRecommendedImg}
                      alt={`Restaurant logo ${index + 1}`}
                      style={{ width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(1px)' }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        textAlign: 'center',
                        padding: '10px',
                        boxSizing: 'border-box',
                        fontSize: '12px',
                      }}
                    >
                      {category.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default RecommendedWithCategoriesRestaurantCards;
