import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, TextField, List, ListItem, Avatar, ListItemText, Card, CardContent, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, Button } from '@mui/material';
import { List as ListIcon } from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import { Favorite } from '@mui/icons-material';
import moment from 'moment';
import exportToExcel from '../exportToExcel';


const ClientList = ({ validUserList, eventsList }) => {
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    if (validUserList && validUserList.length > 0) {
      setExportData(validUserList.map(user => ({
        "Client Name": user.clientName || "",
        "Contact": user.clientContact || "",
        "Email": user.clientEmail || "",
        "Sign-Up Date": moment(user.signUpDate).format('YYYY-MM-DD') || "",
        "Total Reservations": eventsList.filter(event => event.clientId === user._id).length || 0,
        "Favorite Restaurants": user.favRestaurantsData && user.favRestaurantsData.length > 0 ? user.favRestaurantsData.map(restaurant => restaurant.restaurantName).join(", ") : "-",
      })));
    }
  }, [validUserList, eventsList]);

  const handleClientClick = (clientId) => {
    setSelectedClientId(clientId);
  };

  const handleSortClick = (column) => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    // Sorting logic here, depending on `column`
  };

  // Filter clients data based on search query
  const selectedClient = selectedClientId ? validUserList.find(user => user._id === selectedClientId) : null;

  // Filter events data based on selected restaurant
  const selectedEvents = selectedClient ? eventsList.filter(event => event.clientId === selectedClient._id) : [];

  const handleExport = () => {
    exportToExcel(exportData, "ExportedClientsData - " + moment().format('YYYY-MM-DD'));
  };

  return (
    <>
      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{
          mt: 3,
          mb: 2,
          color: "#1F487E",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          letterSpacing: 1,
        }}
      >
        <ListIcon sx={{ color: "#ed9c00", fontSize: "2.2rem" }} />
        Clients List
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={handleExport}
        sx={{ backgroundColor: "#ed9c00", mb: 2 }}
      >
        Export to Excel
      </Button>

      <Grid container spacing={3}>
        {/* Clients List */}
        <Grid item xs={12} md={3}>
          <Paper sx={{ maxHeight: 400, overflow: 'auto', padding: 2, backgroundColor: '#f5f5f5', boxShadow: 3, borderRadius: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, color: '#333', fontWeight: 'bold' }}>
              Clients
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search clients"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              sx={{ mb: 2, borderColor: "#ed9c00" }}
            />

            <List sx={{ padding: 0 }}>
              {validUserList.filter(user => {
                const name = user.clientName || user.clientEmail.split('@')[0];
                return name.toLowerCase().includes(searchQuery.toLowerCase());
              }).map(user => (
                <ListItem
                  component="button"
                  key={user._id}
                  onClick={() => handleClientClick(user._id)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: '#fff',
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    padding: 1.5,
                    marginBottom: 1.5,
                    '&:hover': {
                      backgroundColor: "#ed9c00",
                      color: '#fff',
                    },
                    transition: 'background-color 0.2s ease-in-out',
                  }}
                >
                  <Avatar sx={{ width: 32, height: 32, marginRight: 2 }}>
                    {user.clientName ? user.clientName.charAt(0).toUpperCase() : user.clientEmail.charAt(0).toUpperCase()}
                  </Avatar>
                  <ListItemText
                    primary={user.clientName || user.clientEmail.split('@')[0]}
                    sx={{
                      color: '#333',
                      fontWeight: user.clientName ? 'medium' : 'light',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* User Details Card */}
        <Grid item xs={12} md={9}>
          <Card sx={{ padding: 2, backgroundColor: '#f9f9f9', boxShadow: 4, borderRadius: 2, borderTop: `4px solid #ed9c00` }}>
            <CardContent>
              {selectedClient ? (
                <>
                  <Grid container spacing={1}>
                    {/* Client Name */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <AccountCircleIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {selectedClient.clientName || "N/A"}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>Name</Typography>
                      </Paper>
                    </Grid>

                    {/* Contact */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <PhoneIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {selectedClient.clientContact || "N/A"}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>Contact</Typography>
                      </Paper>
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6} md={4}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <EmailIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333", wordBreak: "break-all" }}>
                            {selectedClient.clientEmail || "N/A"}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>Email</Typography>
                      </Paper>
                    </Grid>

                    {/* Sign Up date */}
                    <Grid item xs={12} sm={6} md={2}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <AccountCircleIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {moment(selectedClient.signUpDate).format('YYYY-MM-DD') || "N/A"}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>Sign-Up Date</Typography>
                      </Paper>
                    </Grid>

                    {/* Total Reservations */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Paper elevation={3} sx={{ padding: 2 }}>
                        <Box display="flex" alignItems="center">
                          <GroupIcon color="primary" sx={{ mr: 1 }} />
                          <Typography sx={{ fontSize: 14, fontWeight: "bold", color: "#333" }}>
                            {selectedEvents.length || 0}
                          </Typography>
                        </Box>
                        <Typography variant="caption" sx={{ color: "#555" }}>Total Reservations Made</Typography>
                      </Paper>
                    </Grid>
                  </Grid>

                  {/* Favorite Restaurants */}
                  <Typography variant="h6" sx={{ mt: 3, color: '#333', fontWeight: 'bold', display: 'flex', alignItems: 'center', fontSize: '1rem' }}>
                    <Favorite sx={{ mr: 1, color: '#ed9c00', fontSize: '1.2rem' }} /> 
                    Favourite Restaurants
                  </Typography>
                  <Grid container spacing={3}>
                    {selectedClient.favRestaurantsData && selectedClient.favRestaurantsData.length > 0 ? (
                      selectedClient.favRestaurantsData.map((restaurant) => (
                        <Grid item xs={12} sm={6} md={4} key={restaurant._id}>
                          <Card sx={{ borderRadius: 2, boxShadow: 2, overflow: 'hidden', position: 'relative', '&:hover': { boxShadow: 4, transform: 'scale(1.05)', transition: '0.3s' } }}>
                            <CardContent sx={{ padding: 1.5 }}>
                              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', fontSize: '0.9rem' }}>
                                {restaurant.restaurantName}
                              </Typography>
                              <Typography variant="body2" color="textSecondary" sx={{ mt: 1, fontSize: '0.8rem' }}>
                                {restaurant.restaurantCity + ", " + restaurant.restaurantState || 'Address not available'}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant="body2" color="textSecondary" align="center" sx={{ fontSize: '0.8rem' }}>
                          No favorite restaurants added yet.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  
                  {/* User Events */}
                  <Typography variant="h6" sx={{ mt: 2, color: '#333', fontWeight: 'bold' }}>Reservations</Typography>
                  <TableContainer component={Paper} sx={{ maxHeight: 400, mt: 2, boxShadow: 3 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Restaurant Name</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Reservation Date</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Reservation Time</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>No. of Pax</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Packages Selected</TableCell>
                          <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#1F487E', color: '#fff' }}>Remark by Customer</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedEvents && selectedEvents.length > 0 ? (
                          selectedEvents.map((event) => (
                            <TableRow key={event._id}>
                              <TableCell>{event.restaurantName}</TableCell>
                              <TableCell>{new Date(event.eventDate).toLocaleDateString()}</TableCell>
                              <TableCell>{event.eventTime}</TableCell>
                              <TableCell>{event.noOfPax || ""}</TableCell>
                              <TableCell>
                                {event.packages && event.packages.length > 0 ? (
                                  event.packages.map((pkg, index) => (
                                    <div key={index}>
                                      {pkg.packageQuantity} {pkg.packageName} 
                                    </div>
                                  ))
                                ) : (
                                  "--"
                                )}
                              </TableCell>
                              <TableCell>{event.remark || ""}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">No Reservations</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <Typography variant="body2" color="textSecondary">Select a user to view details</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ClientList;
