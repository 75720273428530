import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import { useAuth } from "../../../services/authContext";
import useClientServices from "../../../services/clientService";
import RestaurantCardsBody from "./RestaurantCardsBody";
import useRestaurantServices from "../../../services/restaurantService";
import defaultImage from "../../../assets/dist/img/default_photo.png";


const RestaurantCards = () => {
  const { user } = useAuth();
  const { restaurantsData } = useRestaurantServices();
  const [newPhotosList, setNewPhotosList] = useState({});
  const [lists, setList] = useState([]);
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const { fetchClientInfo, clientInfo } = useClientServices();
  const history = useHistory();

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (restaurantsData.length > 0) {
      // Append restaurant logo into first photoFiles array
      const photosList = {};
      restaurantsData.forEach((restaurant => {
        const photos = restaurant.restaurantLogo 
          ? [restaurant.restaurantLogo, ...restaurant.photoFiles] 
          : [defaultImage, ...restaurant.photoFiles];
        photosList[restaurant._id] = photos;
      }));
      setList(restaurantsData);
      setNewPhotosList(photosList);
    }
  }, [restaurantsData]);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      if (user.role === "client") {
        fetchClientInfo(decodedToken.userId);
      }
    }
  }, [decodedToken]);

  return (
    <div className="hk-pg-body p-3 font-monospace">
      <RestaurantCardsBody restaurantsInfo={lists} clientInfo={clientInfo}/>
    </div>
  );
};

export default RestaurantCards;
