import * as XLSX from 'xlsx';

const exportToExcel = (data, fileName) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Add a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

  // Create a downloadable Excel file
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export default exportToExcel;