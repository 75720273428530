import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

import { useAuth } from "../../../../services/authContext";
import useClientServices from "../../../../services/clientService";
import useRestaurantServices from "../../../../services/restaurantService";
import RestaurantCardsBody from "../RestaurantCardsBody";


const CategoryRestaurantCards = () => {
  const { user } = useAuth();
  const { restaurantsData } = useRestaurantServices();
  const { fetchClientInfo, clientInfo } = useClientServices();
  const [token, setToken] = useState(Cookies.get("token"));
  const [decodedToken, setDecodedToken] = useState(null);
  const { category } = useParams();
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(category);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadRestaurants = async () => {
      setLoading(true);
      try {
        if (restaurantsData || category !== currentCategory) {
          const filtered = restaurantsData.filter(restaurant => restaurant.tags.toLowerCase().includes(currentCategory.toLowerCase()));
          setFilteredRestaurants(filtered);
          setCurrentCategory(currentCategory.toLowerCase());
        }
      } catch (error) {
        console.error("Error loading restaurants. ", error.message);
      } finally {
        setLoading(false);
      }
    };
    loadRestaurants();
  }, [category, restaurantsData]);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      if (user.role === "client") {
        fetchClientInfo(decodedToken.userId);
      }
    }
  }, [decodedToken]);

  return (
    <>
      <div className="hk-pg-body p-3 font-monospace">
        {console.log('loading', loading)}
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center text-center"
            style={{ height: "200px" }}
          >
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Fetching Restaurants...</span>
            </div>
          </div>
        //) : filteredRestaurants.length === 0 ? (
        //  <div
        //    className="d-flex justify-content-center align-items-center text-center"
        //    style={{ height: "200px" }}
        //  >
        //    Uh-oh! No bites here. How about trying something different?
        //  </div>
        ) : (
          <RestaurantCardsBody
            restaurantsInfo={filteredRestaurants}
            clientInfo={clientInfo}
          />
        )}
      </div>
    </>
  );
};

export default CategoryRestaurantCards;
