import React, { useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Grid, Card, CardContent, Box, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { BarChart as BarChartIcon } from "@mui/icons-material";
import GroupIcon from '@mui/icons-material/Group';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import moment from "moment";


const RestaurantAnalytics = ({ restaurantsList, eventsList, validClientsList }) => {
  const filteredRestaurantsList = restaurantsList.filter(restaurant => restaurant.restaurantName !== 'Zemo Restaurant');
  const filteredEventsList = eventsList.filter(event => {
    const restaurantName = restaurantsList.find(restaurant => restaurant._id === event.restaurantId)?.restaurantName;
    return restaurantName !== 'Zemo Restaurant';
  });

  const [timeFilter, setTimeFilter] = useState("daily");

  const handleFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  // Function to filter sign-up data based on selected filter
  const filterSignUpData = (data, filter) => {
    const groupedData = {};

    data.forEach((restaurant) => {
      let dateKey;
      if (filter === "daily") {
        dateKey = moment(restaurant.onBoardedStartDate).format("YYYY-MM-DD");
      } else if (filter === "weekly") {
        dateKey = `Week ${moment(restaurant.onBoardedStartDate).isoWeek()}, ${moment(restaurant.onBoardedStartDate).year()}`;
      } else if (filter === "monthly") {
        dateKey = moment(restaurant.onBoardedStartDate).format("YYYY-MM");
      }

      if (!groupedData[dateKey]) {
        groupedData[dateKey] = 0;
      }
      groupedData[dateKey] += 1;
    });
    return Object.entries(groupedData).map(([date, count]) => ({ date, count }));
  };

  const filteredData = filterSignUpData(filteredRestaurantsList, timeFilter);

  const chartData = {
    labels: filteredData.map((data) => data.date),
    datasets: [{
      label: "On-Boards",
      data: filteredData.map((data) => data.count),
      backgroundColor: "#ed9c00",
      borderColor: "#ed9c00",
      borderWidth: 1,
    }],
  };

  // Filter clients data based on most recent reservation events and browsing activity (2 months)
  const filteredActiveClients = validClientsList.filter(client => {
    const userEvents = filteredEventsList.filter(event => event.clientId === client._id);
    const mostRecentEvent = userEvents.length > 0 ? userEvents.reduce((prev, current) => (prev.eventDate > current.eventDate) ? prev : current) : null;
    return mostRecentEvent && moment().diff(moment(mostRecentEvent.eventDate), 'months') >= 2;
  });

  return (
    <Box sx={{ padding: 3 }}>
      {/* Header Section */}
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{
          mb: 4,
          color: "#1F487E",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          letterSpacing: 1,
        }}
      >
        <BarChartIcon sx={{ color: "#ed9c00", fontSize: "2.2rem" }} />
        Restaurant Analytics
      </Typography>

      <Grid container spacing={2} mb={6}>
        {/* Card for Total Added Restaurants */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: 3,
              boxShadow: 6,
              bgcolor: "#f9f9f9",
              border: `1px solid #ed9c00`,
              transition: "0.3s",
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 10,
              },
            }}
          >
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <GroupIcon sx={{ fontSize: '2.5rem', color: '#ed9c00' }} />
              <Box>
                <Typography variant="h7" sx={{ color: "#ed9c00", fontWeight: 'bold' }}>
                  Total Restaurants Added
                </Typography>
                <Typography variant="h6" sx={{ color: "#333", fontWeight: 'bold' }}>
                  {filteredRestaurantsList.length}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Total On-Boarded Restaurants */}
        <Grid item xs={12} md={3}>
          <Card
            sx={{
              borderRadius: 3,
              boxShadow: 6,
              bgcolor: "#f9f9f9",
              border: `1px solid #ed9c00`,
              transition: "0.3s",
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 10,
              },
            }}
          >
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <AdminPanelSettingsIcon sx={{ fontSize: '2.5rem', color: '#ed9c00' }} />
              <Box>
                <Typography variant="h7" sx={{ color: "#ed9c00", fontWeight: 'bold' }}>
                  Total On-Boarded
                </Typography>
                <Typography variant="h6" sx={{ color: "#333", fontWeight: 'bold' }}>
                  {filteredRestaurantsList.filter(restaurant => restaurant.isBoarded).length}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Percentage of OnBoarded/TotalRestaurants */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: 3,
              boxShadow: 6,
              bgcolor: "#f9f9f9",
              border: `1px solid #ed9c00`,
              transition: "0.3s",
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 10,
              },
            }}
          >
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <AdminPanelSettingsIcon sx={{ fontSize: '2.5rem', color: '#ed9c00' }} />
              <Box>
                <Typography variant="h7" sx={{ color: "#ed9c00", fontWeight: 'bold' }}>
                  OnBoarded / TotalRestaurantsAdded
                </Typography>
                <Typography variant="h6" sx={{ color: "#333", fontWeight: 'bold' }}>
                  {((filteredRestaurantsList.filter(restaurant => restaurant.isBoarded).length / filteredRestaurantsList.length) * 100).toFixed(2)}%
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Card for Active Users */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: 3,
              boxShadow: 6,
              bgcolor: "#f9f9f9",
              border: `1px solid #ed9c00`,
              transition: "0.3s",
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 10,
              },
            }}
          >
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <AdminPanelSettingsIcon sx={{ fontSize: '2.5rem', color: '#ed9c00' }} />
              <Box>
                <Typography variant="h7" sx={{ color: "#ed9c00", fontWeight: 'bold' }}>
                  Active Users (2 months) (Reservation)
                </Typography>
                <Typography variant="h6" sx={{ color: "#333", fontWeight: 'bold' }}>
                  {filteredActiveClients.length}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{
          mb: 4,
          color: "#1F487E",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          letterSpacing: 1,
        }}
      >
        <BarChartIcon sx={{ color: "#ed9c00", fontSize: "2.2rem" }} />
        On-Boarded Restaurants by Day, Week, Month
      </Typography>

      {/* Filter Section */}
      <Grid item xs={12} md={6}>
        <FormControl sx={{ minWidth: 200, mb: 2 }}>
          <InputLabel>Filter By</InputLabel>
          <Select value={timeFilter} label="Filter By" onChange={handleFilterChange}>
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Chart Section */}
      <Grid item xs={12}>
        <Card
          sx={{
            borderRadius: 3,
            boxShadow: 6,
            bgcolor: "#fff",
            padding: 3,
          }}
        >
          <Bar 
            data={chartData} 
            options={{ 
              responsive: true, 
              maintainAspectRatio: false,
              plugins: {
                legend: { display: false },
                tooltip: {
                  callbacks: {
                    label: (context) => `${context.raw} On-Boards`,
                  },
                },
              },
              scales: {
                x: { grid: { display: false } },
                y: { grid: { color: "#e0e0e0" } },
              },
            }} 
            height={300} 
          />
        </Card>
      </Grid>
    </Box>
  );
};
  
export default RestaurantAnalytics;