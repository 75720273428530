import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Cookies from "js-cookie";

import useClientServices from "../../../services/clientService";
import useRestaurantServices from "../../../services/restaurantService";
import useEventServices from "../../../services/eventService";
import RestaurantAnalytics from "./RestaurantAnalytics";
import RestaurantList from "./RestaurantList";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const RestaurantPage = () => {
  const [adminList, setAdminList] = useState([]);
  const { clientsData } = useClientServices();
  const [clientsList, setClientsList] = useState([]);
  const [validClientsList, setValidClientsList] = useState([]);
  const { restaurantsData } = useRestaurantServices();
  const [restaurantsList, setRestaurantsList] = useState([]);
  const { eventsData } = useEventServices();
  const [eventsList, setEventsList] = useState([]);

  const [Token, setToken] = useState(Cookies.get("token"));
  useEffect(() => {
    fetchData(Token);
  }, []);

  const fetchData = async (Token) => {
    await fetch(`${backendUri}/api/v0/super-admin/admins`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setAdminList(data);
        } else {
          setAdminList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to :" + err.message);
      });
  };

  useEffect(() => {
    if (clientsData) {
      setClientsList(clientsData.clients);

      const validUsers = clientsData.clients.filter(client => client.clientEmail);
      setValidClientsList(validUsers);
    }
  }, [clientsData]);

  useEffect(() => {
    if (restaurantsData) {
      setRestaurantsList(restaurantsData);
    }
  }, [restaurantsData]);

  useEffect(() => {
    if (eventsData) {
      setEventsList(eventsData);
    }
  }, [eventsData]);

  return (
    <Box className="font-monospace" p={3}>
      <RestaurantAnalytics restaurantsList={restaurantsList} eventsList={eventsList} validClientsList={validClientsList}/>
      <RestaurantList adminList={adminList} restaurantsList={restaurantsList} eventsList={eventsList} clientsList={clientsList} />
    </Box>
  );
};

export default RestaurantPage;
