import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useAuth } from "../services/authContext";


// eslint-disable-next-line no-undef
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const useClientServices = () => {
  const [clientInfo, setClientInfo] = useState("");
  const [clientsData, setClientsData] = useState("");
  const accessToken = Cookies.get("token");
  const { user } = useAuth();

  useEffect(() => {
    getClients();
  }, [accessToken]);

  const fetchClientInfo = async (clientId) => {
    if(!clientId || (user.role && user.role === "superAdmin") || !accessToken) {
      return;
    }
    try {
      const response = await fetch(
        `${backendUri}/api/v0/admin/clients/${clientId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const clientData = await response.json();
        setClientInfo(clientData);
      }
    } catch (error) {
      console.error("Error fetching client information:", error);
    }
  };

  const getClients = async () => {
    if (!user || Object.keys(user).length === 0 || user.role !== "superAdmin") {
      return;
    }

    try {
      const headers = {
        "Content-Type": "application/json",
      };

      let fetchClientsAPI;
      fetchClientsAPI = `${backendUri}/api/v0/super-admin/clients/`;
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }

      const response = await fetch(fetchClientsAPI, {
        method: "GET",
        headers,
      });
      if (response.ok) {
        const responseData = await response.json();
        setClientsData(responseData);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };


  return {
    fetchClientInfo,
    clientInfo,
    getClients,
    clientsData,
  };
};

export default useClientServices;
