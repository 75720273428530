import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Grid, Card, CardContent, Box, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { BarChart as BarChartIcon } from "@mui/icons-material";
import GroupIcon from '@mui/icons-material/Group';
import moment from "moment";

import useEventServices from "../../../services/eventService";
import useRestaurantServices from "../../../services/restaurantService";


const CRMPage = () => {
  const { restaurantsData } = useRestaurantServices();
  const { eventsData } = useEventServices();
  const [eventsList, setEventsList] = useState([]);
  const [timeFilter, setTimeFilter] = useState("daily");

  useEffect(() => {
    if (eventsData && restaurantsData) {
      const filteredEvents = eventsData.filter(event => {
        const restaurantName = restaurantsData.find(restaurant => restaurant._id === event.restaurantId)?.restaurantName;
        return restaurantName !== 'Zemo Restaurant';
      });
      setEventsList(filteredEvents);
    }
  }, [eventsData, restaurantsData]);

  const handleFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  // Function to filter sign-up data based on selected filter
  const filterEventsData = (data, filter) => {
    const groupedData = {};

    data.forEach((event) => {
      let dateKey;
      if (filter === "daily") {
        dateKey = moment(event.eventDate).format("YYYY-MM-DD");
      } else if (filter === "weekly") {
        dateKey = `Week ${moment(event.eventDate).isoWeek()}, ${moment(event.eventDate).year()}`;
      } else if (filter === "monthly") {
        dateKey = moment(event.eventDate).format("YYYY-MM");
      }

      if (!groupedData[dateKey]) {
        groupedData[dateKey] = 0;
      }
      groupedData[dateKey] += 1;
    });
    return Object.entries(groupedData).map(([date, count]) => ({ date, count }));
  };

  const filteredData = filterEventsData(eventsList, timeFilter);

  const chartData = {
    labels: filteredData.map((data) => data.date),
    datasets: [{
      label: "Reservations",
      data: filteredData.map((data) => data.count),
      backgroundColor: "#ed9c00",
      borderColor: "#ed9c00",
      borderWidth: 1,
    }],
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Header Section */}
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{
          mb: 4,
          color: "#1F487E",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          letterSpacing: 1,
        }}
      >
        <BarChartIcon sx={{ color: "#ed9c00", fontSize: "2.2rem" }} />
        Events Analytics
      </Typography>

      <Grid container spacing={2} mb={6}>
        {/* Card for Total Reservations */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              borderRadius: 3,
              boxShadow: 6,
              bgcolor: "#f9f9f9",
              border: `1px solid #ed9c00`,
              transition: "0.3s",
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: 10,
              },
            }}
          >
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <GroupIcon sx={{ fontSize: '2.5rem', color: '#ed9c00' }} />
              <Box>
                <Typography variant="h7" sx={{ color: "#ed9c00", fontWeight: 'bold' }}>
                  Total Reservations
                </Typography>
                <Typography variant="h6" sx={{ color: "#333", fontWeight: 'bold' }}>
                  {eventsList.length}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography 
        variant="h6" 
        gutterBottom 
        sx={{
          mb: 4,
          color: "#1F487E",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          letterSpacing: 1,
        }}
      >
        <BarChartIcon sx={{ color: "#ed9c00", fontSize: "2.2rem" }} />
        Reservations by Day, Week, Month
      </Typography>

      {/* Filter Section */}
      <Grid item xs={12} md={6}>
        <FormControl sx={{ minWidth: 200, mb: 2 }}>
          <InputLabel>Filter By</InputLabel>
          <Select value={timeFilter} label="Filter By" onChange={handleFilterChange}>
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Chart Section */}
      <Grid item xs={12}>
        <Card
          sx={{
            borderRadius: 3,
            boxShadow: 6,
            bgcolor: "#fff",
            padding: 3,
          }}
        >
          <Bar 
            data={chartData} 
            options={{ 
              responsive: true, 
              maintainAspectRatio: false,
              plugins: {
                legend: { display: false },
                tooltip: {
                  callbacks: {
                    label: (context) => `${context.raw} Reservations`,
                  },
                },
              },
              scales: {
                x: { grid: { display: false } },
                y: { grid: { color: "#e0e0e0" } },
              },
            }} 
            height={300} 
          />
        </Card>
      </Grid>
    </Box>
  );
};

export default CRMPage;
